<template>
  <b-card>
    <g-table
      ref="payrollManager-table"
      :items="items"
      :columns="tableColumns"
      :no-action="true"
      per-page="25"
      :total-rows="totalRows"
      :createButton="{ visiable: false }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push({ name: '' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <!-- define edit button -->
          <b-button
            v-b-tooltip.hover.top="$t('preview')"
            v-permission="'editCalculateSalary'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EyeIcon"
              class="mx-25 clickable"
            />
          </b-button>
          <b-button
            v-permission="'editCalculateSalary'"
            v-b-tooltip.hover.top="item.attachmentUrl !== null
              ? $t('hasAttachMents')
              : $t('hasNotAttachMents')
            "
            data-action-type="download"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              if (item.attachmentUrl !== null) {
                downloadItem(item);
              }
            }
            "
          >
            <feather-icon
              :icon="item.attachmentUrl !== null ? 'FolderPlusIcon' : 'FolderIcon'"
              stroke="orange"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteCalculateSalary'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script>
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      filterOn: [],
      perPage: 25,
      rows: rows,
      modal: false,
      filter: {},
    };
  },

  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: 'employeeArabicName',
          label: this.$t('employeeName'),
          sortable: true,
        },
        { key: 'month', label: this.$t('month'), sortable: true },
        { key: 'year', label: this.$t('year') },
        { key: 'salary', label: this.$t('netSalary'), sortable: true },
        {
          key: 'isPosted',
          label: this.$t('isPosted'),
          formatter: (key, value, tanx) => {
            if (tanx.isPosted) return `<span class="p-50 main-space badge bg-secondary bg-darken-1">${this.$t('posted')}</span>`
            return `<span class="p-50 main-space badge bg-danger bg-darken-2">${this.$t('unPosted')}</span>`;
          }
        },
        { key: 'actions' },
      ];
    },
  },
mounted() {
  this.getItems();
},
  methods: {
    edit(item) {
      this.$router.push({
        name: 'salaryEdit',
        params: { id: item.employeeId },
        query: { month: item.month, year: item.year },
      });
    },
    refreshItems() {
      this.$refs['payrollManager-table'].refreshTable();
    },
    getItems() {
      this.get({ url: 'CalculateSaleries' }).then((data) => {
        this.items = data;
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({
            url: 'CalculateSaleries',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    print(item) {
      const reportName = this.isRight ? 'EmployeePayrolls-ar' : 'EmployeePayrolls-en';
      const printedItem = {
        isForSingleTransaction: true,
        employeeId: item.employeeId,
        fromMonth: item.month,
        fromYear: item.year,
        branchName: this.currentBranch.arabicName,
      };
      this.printReport(reportName, printedItem);
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    }
  },
};
</script>
